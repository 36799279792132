import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';
import { styled, Paper } from '@mui/material';
import { Box, Button, Grid, InputAdornment, TableSortLabel, Typography, TextField, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { makeStyles, withStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import { DataGrid, GridRenderCellParams, gridClasses } from '@mui/x-data-grid';
import { Auth } from 'aws-amplify';
import Link from '@mui/material/Link';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '20px',
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const ModelTablestyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const NEWTableHead = withStyles((theme) => ({
    root: {
      backgroundColor: "#FAFBFF"
    }
  }))(TableHead);
  
  const Row = (props) => {
    const { row, labelId, onClick } = props;
    const [open, setOpen] = React.useState(false);
  
    function getTimeFromTimeStamp(timestamp) {
      var separatedString = timestamp.split("T")
      return separatedString[1]
    }

    function getDateFromTimeStamp(timestamp) {
      var separatedString = timestamp.split("T")
      return separatedString[0]
    }

    return (
      <React.Fragment>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell id={labelId}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            {row.deployment_id ? row.deployment_id : 'N/A'}
          </TableCell>
          <TableCell>{row.model_deployment_type ? row.model_deployment_type : 'N/A'}</TableCell>
          <TableCell>{row.deployment_location}</TableCell>
          <TableCell>{row.status ? row.status : 'N/A'}</TableCell>
          <TableCell>{row.schedule_time ? row.schedule_time : 'N/A'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Deployment Schedule ID</TableCell>
                      {/* <TableCell>DC Name</TableCell> */}
                      <TableCell>PC Name</TableCell>
                      <TableCell>Tried Attempts</TableCell>
                      <TableCell>Deployment Start Time</TableCell>
                      <TableCell>Deployment End Time</TableCell>
                      <TableCell>Model Active Date</TableCell>
                      <TableCell>Model Set Id</TableCell>
                      <TableCell>Number of Models Behind</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.schedules.map((schedule) => (
                      <TableRow key={schedule.deployment_schedule_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {schedule.deployment_schedule_id ? schedule.deployment_schedule_id : 'N/A'}
                        </TableCell>
                        {/* <TableCell>{schedule.dc_name ? schedule.dc_name : '-'}</TableCell> */}
                        <TableCell>{schedule.pc_name ? schedule.pc_name : '-'}</TableCell>
                        <TableCell>{schedule.tried_attempts ? schedule.tried_attempts : '-'}</TableCell>
                        <TableCell>{schedule.deployment_start_time ? getTimeFromTimeStamp(schedule.deployment_start_time) : '-'}</TableCell>
                        <TableCell>{schedule.deployment_end_time ? getTimeFromTimeStamp(schedule.deployment_end_time) : '-'}</TableCell>
                        <TableCell>{schedule.creation_date ? getDateFromTimeStamp(schedule.creation_date) : '-'}</TableCell>
                        <TableCell onClick={() => onClick(schedule)}><Typography style={{display:'inline-flex', alignItems: 'baseline', fontWeight: 600}} sx={{textDecoration: 'underline'}} display="inline">{schedule.model_set_id ? schedule.model_set_id : '-'}</Typography></TableCell>
                        <TableCell>{schedule.no_of_models_behind ? schedule.no_of_models_behind : '-'}</TableCell>
                        <TableCell>{schedule.status ? schedule.status : '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }


const CICDUIDeploymentTracking = ({ user, ...props }) => {
 
    const [indicatorOn, setIndicatorOn] = useState(false);
    const [deploymentDetails, setDeploymentDetails] = useState(props.deploymentDetails)
    const [showdataView, setShowdataView] = useState(false);
    const [nodataView, setNodataView] = useState(false);
    const [showModelSetDataView, setShowModelSetDataView] = useState(false);
    const [showModelSetData, setShowModelSetData] = useState([]);

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#fff"
    });

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }

    function getComparator(order, orderBy) {
        return order === "desc"
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }

    function EnhancedTableHead(props) {
        const {
          order,
          orderBy,
          onRequestSort
        } = props;
        const createSortHandler = (property) => (event) => {
          onRequestSort(event, property);
        };
    }

    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            localStorage.setItem('authToken', jwt);
            props.fetchCustomersWithDC();
            props.fetchDeploymentDetails()
            setIndicatorOn(true);
            setShowdataView(false)
            setShowModelSetDataView(false)
            setNodataView(true)
        })
    }, []);

    function getDateFromTimeStamp(timestamp) {
      if (timestamp !== null) {
        var separatedString = timestamp.split("T")
        return separatedString[0]
      }
      return timestamp
    }
    useEffect(() => {
        if(!props.deploymentDetails) {
            props.fetchDeploymentDetails()
            setIndicatorOn(true);
            setShowdataView(false)
            setNodataView(true)
            setShowModelSetDataView(false)
        }else{
            props.deploymentDetails.sort(function(x,y){ return x.status == 'Failure' && getDateFromTimeStamp(x.schedule_time) == getDateFromTimeStamp(y.schedule_time)? -1 : y.status == 'Failure'? 1 : 0; });
            setDeploymentDetails(props.deploymentDetails)
            setIndicatorOn(false);
            setShowdataView(true)
            setNodataView(false)
            setShowModelSetDataView(false)
        }
    }, [props.deploymentDetails]);

    useEffect(() => {
      if(props.modeSetIdDetails !== null) {
         setIndicatorOn(false);
         let newModelSKUData = {
          missing_skus: props.modeSetIdDetails.missing_skus.join(", "),
          new_skus: props.modeSetIdDetails.new_skus.join(", ")
         }
         setShowModelSetData(newModelSKUData)
         setShowModelSetDataView(true)
      }else{
          setShowModelSetDataView(false)
          setIndicatorOn(false);
          setShowModelSetData([])
      }
  }, [props.modeSetIdDetails]);

    useEffect(() => {
    if(props.error !== null) {
       setIndicatorOn(false);
       props.resetError()
    }
    }, [props.error]);

    const columns = [
        { field: "deployment_id", headerName: "Deployment ID" },
        { field: "model_deployment_type", headerName: "Model deployment type" },
        { field: "deployment_location", headerName: "Deployment location" },
        { field: "status", headerName: "Deployment status" },
        { field: "schedule_time", headerName: "Deployment time" }

      ];

      const handleOnCellClick = (schedule) => {
        console.log(schedule)
        setIndicatorOn(true);
        props.getModelSkuData(schedule.deployment_schedule_id, schedule.model_set_id, schedule.deployment_start_time)
       
    };

    const handleClose = () => {
      setShowModelSetData([])
      setShowModelSetDataView(false)
    }

    return (
        <div style={{ width: '100%' }}>
            {indicatorOn ?
                <div>
                    <Backdrop 
                        sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={indicatorOn}
                    >
                        <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                    </Backdrop>
                </div> : '' 
                }
                <div>  
                {props.deploymentDetails !== null &&
                 <Grid container 
                 >
                   {/* <Grid item xs={3}>
                     <Box sx={{ width: "100%" }} mt={4} mb={4}>
                       <TextField
                         value={props?.searchInput}
                         onChange={(e) => {
                           handleChange(e.target.value);
                         }}
                         autoComplete="off"
                         sx={{
                           borderRadius: "4px",
                           input: {
                             color: "#033F53",
                           },
                           width: "100%",
                           background: "#fff",
                           boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                           "& .MuiInputBase-root.Mui-disabled": {
                             background: "#E0E2EA",
                             color: "#A6A6A6",
                           },
                         }}
                         size={"small"}
                         placeholder="Search"
                         id="outlined-start-adornment"
                         InputProps={{
                           startAdornment:
                             <InputAdornment position="start">
                               <img src={SearchIcon} alt="" />
                             </InputAdornment>
                         }}
                       />
                     </Box>
                   </Grid> */}
                   {/* <Grid item xs={9}>
                     <Box mt={4} mb={4} display={"flex"} justifyContent={"end"}>
                       <Button
                         onClick={handleStoreLocationDownload}
                         startIcon={<FileDownloadIcon />}
                         variant="contained"
                         style={{
                           backgroundColor: "#3b62a1"
                         }}
                       >
                         Export Data
                       </Button>
                     </Box>
                   </Grid> */}
                 </Grid>
               }
               {showdataView && (
                 <TableContainer component={Paper}>
                   <Table aria-label="collapsible table">
                     {/* <EnhancedTableHead
                    //    order={order}
                    //    orderBy={orderBy}
                    //    onRequestSort={handleRequestSort}
                       rowCount={deploymentDetails.length}
                     /> */
                     }
                       <TableHead>
                     <TableRow>
                        <TableCell />
                        <TableCell>Deployment ID</TableCell>
                        <TableCell >Model deployment type</TableCell>
                        <TableCell >Deployment location</TableCell>
                        <TableCell >Deployment status</TableCell>
                        <TableCell >Deployment time</TableCell>
                    </TableRow>
                    </TableHead>
                     {deploymentDetails.length > 0 ?
                       <TableBody>
                         {deploymentDetails.map(
                           (row, index) => {
                             const labelId = `enhanced-table-checkbox-${index}`;
                             return (
                               <Row key={row.deployment_id} row={row} labelId={labelId} onClick={(handleOnCellClick)}/>
                             )
                           })}
                       </TableBody> :
                       <TableBody>
                         <TableRow>
                           <TableCell scope="row" align="center" colSpan={6}>
                             No Data
                           </TableCell>
                         </TableRow>
                       </TableBody>
                     }
                   </Table>
                 </TableContainer>
               )}
               {nodataView && (
                 <TableContainer component={Paper}>
                   <Table aria-label="collapsible table">
                     <NEWTableHead>
                       <TableRow>
                         <TableCell>Deployment ID</TableCell>
                         <TableCell>Model deployment type</TableCell>
                         <TableCell align="right">Deployment locations</TableCell>
                         <TableCell align="right">Deployment status</TableCell>
                         <TableCell align="right">Deployment time</TableCell>
                       </TableRow>
                     </NEWTableHead>
                     <TableBody>
                       <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                         <TableCell scope="row" align="center" colSpan={5}>
                           No Data
                         </TableCell>
                       </TableRow>
                     </TableBody>
                   </Table>
                 </TableContainer>
               )}
               <Modal
                    open={showModelSetDataView}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={ModelTablestyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Model Set ID Data
                    </Typography>
                    {showModelSetDataView && (
                       <div style={{ height: '100%', width: '100%', whiteSpace: 'pre-wrap', overflow: 'scroll' }}>
                       <Typography id="modal-modal-title" variant="h6" component="h2">
                          Model Set ID - {props.modeSetIdDetails.model_set_id}
                      </Typography>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                          Previous Model Set ID - {props.modeSetIdDetails.prev_model_set_id}
                       </Typography>
                       <Typography id="modal-modal-title" variant="h6" component="h2">
                        New SKUs  
                      </Typography>
                      <Typography id="modal-modal-title" variant="body1" component="h2">
                         {showModelSetData.new_skus}
                     </Typography>
                       <Typography id="modal-modal-title" variant="h6" component="h2">
                        Missing SKUs 
                      </Typography>
                       <Typography id="modal-modal-title" variant="body1" component="h2">
                        {showModelSetData.missing_skus}
                      </Typography>
                    </div>
                    )}
              
                    </Box>
                </Modal>
               </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        deploymentDetails: state.cicdUIDeploy.deploymentDetails,
        modeSetIdDetails: state.cicdUIDeploy.modeSetIdDetails,
        error: state.cicdUIDeploy.error,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomersWithDC: () => dispatch(Actions.LOAD_CUSTOMERS_WITH_DC()),
        fetchDeploymentDetails: () => dispatch(Actions.LOAD_DEPLOYMENT_DETAILS()),
        getModelSkuData: (deploymentSheduleID, modelSetID, deploymentDate) => dispatch(Actions.GET_MODEL_SKU_DATA(deploymentSheduleID, modelSetID, deploymentDate)),
        resetError: () => dispatch(Actions.RESET_ERROR()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CICDUIDeploymentTracking);