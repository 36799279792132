import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { connect } from 'react-redux'
import CustomerManagement from "./components/CustomerManagement/CustomerManagement";
import EdgePCS from "./components/EdgePCS"
import LocationManagement from "./components/LocationManagement/LocationManagement";
import VORConfiguration from "./components/VORConfiguration/VORConfiguration";
import SkuUpdate from "./components/SkuUpdate/SkuUpdate";
import ImportSKU from "./components/ImportSKUs/ImportSKU";
import CICDUIDeploy from './components/CICDUIDeployment/CICDUIDeployment';
import CICDUIDeploymentTracking from './components/CICDUIDeploymentTracking/CICDUIDeploymentTracking'
import QuickSightHealthMetrics from './components/QuickSightHealthMetrics/QuickSightHealthMetrics'
import DashBoard from './components/DashBoard/DashBoard';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LogoutIcon from '@mui/icons-material/Logout';
import './styles/styles.scss'
import './App.css';
import {
    Snackbar,
    Alert,
    Box,
    Button,
    CssBaseline,
    Drawer,
    Typography,
    Toolbar,
    Divider,
    IconButton,
    styled,
    useTheme,
    List
} from "@mui/material";
import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';
import * as AppActions from "./actions/application";
import { UserManagement } from "rehrig-central-admin-portal"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from "@mui/icons-material/Menu";
import RehrigLogo from './assets/RehrigPacificDuo-IoTBannerLogo.png';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { NavLink as NavLinkBase } from "react-router-dom";
import "./styles.css";
import leftLogo from "./assets/rehrig_left_panel_logo.svg";

const drawerWidth = 240;

const NavLink = styled((props) => (
    <NavLinkBase {...props} end={props.to === "/"} />
))(({ theme }) => ({
    color: '#a5bacd',
    textDecoration: "none",
    fontSize: "13px",
    paddingBottom: '5px',
    width: '98%',
    "&.active": {
        color: "white !important",
        fontWeight: '700',
        fontSize: "13px",
        borderBottom: "2px solid #38d2ea",
    }
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-235px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


function ProtectedApp({ user, ...props }) {
    const [open, setOpen] = useState(false);
    const [toggle, setToggle] = useState(true);
    const [toggleVOR, setTogglevor] = useState(true);
    const [showAccess, setShowAccess] = useState(false);

    useEffect(() => {
        if (!user.canRead) {
            setTimeout(() => setShowAccess(true), 1500);
        } else {
            setShowAccess(false);
        }
    }, [user])

    function AccessDenied() {
        async function signOut() {
            try {
                await Auth.signOut();
            } catch (error) {
                console.log('error signing out: ', error);
            } finally {
                localStorage.removeItem("authToken");
                window.location.reload("/");
            }
        }
        return (<div >
            <h2 style={{ marginLeft: "10px" }}>Access Denied</h2>
            <hr></hr>
            <label style={{ marginLeft: "10px" }}>Only Rehrig users have permission to access this page.</label>
            <label> Please check with your administrator.</label>
            <br></br>
            <a href="#" onClick={signOut} style={{ marginLeft: "10px" }}>Sign Out</a>
        </div>)
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const theme = useTheme();

    const handleClose = () => {
        props.hideNotification();
    }
    async function signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        } finally {
            localStorage.removeItem("authToken");
            window.location.replace('/');
        }
    }

    useEffect(() => {

        Auth.currentUserInfo().then(userInfo => {
            props.setUserInfo(userInfo);
        },)

    }, [])

    const handleCollapse = () => {
        setToggle(toggle => !toggle);
    }

    const handleCollapseVor = () => {
        setTogglevor(toggleVOR => !toggleVOR);
    }

    return (
        <>

            {user.canRead ?
                (<>
                    <BrowserRouter>
                        <Box sx={{ display: 'flex' }}>
                            <CssBaseline />
                            <AppBar position="fixed" open={open}>
                                <Toolbar className="header-navbar" style={{ background: 'linear-gradient(to right, rgb(4,80,143) 0%, #0eb2eb 100%)', height: '120px', padding: '10px' }}>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Typography variant="h6" noWrap component="div">
                                        {!open ?
                                            <img src={RehrigLogo} alt="RPC Logo" className="logo-header" style={{ width: "270px", height: "80px" }} /> : ""
                                        }

                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Drawer
                                sx={{
                                    width: drawerWidth,
                                    flexShrink: 0,
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,
                                        boxSizing: 'border-box',
                                    },
                                }}
                                variant="persistent"
                                anchor="left"
                                open={open}
                            >
                                <DrawerHeader>
                                    <img src={leftLogo} className='left-logo' alt='left-log' />
                                    <IconButton onClick={handleDrawerClose}>
                                        {theme.direction === 'ltr' ? <ChevronLeftIcon sx={{ color: "white" }} /> : <ChevronRightIcon />}
                                    </IconButton>
                                </DrawerHeader>
                                <Divider sx={{ borderColor: "#0879b5" }} />

                                <List>
                                    <div className='List-Header' onClick={handleCollapse}>{toggle ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />} Central Admin</div>
                                    {toggle ?
                                        <div className=''>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <NavLink to='/' className='menu-link-item'>Customer Management</NavLink>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <NavLink to='/locations' className='menu-link-item'>Location Management</NavLink>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <NavLink to='/users' className='menu-link-item'>User Management</NavLink>
                                                </ListItemButton>
                                            </ListItem>
                                        </div> : ""
                                    }
                                </List>

                                <List>
                                    <div className='List-Header' onClick={handleCollapseVor}>{toggleVOR ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />} VOR Admin</div>
                                    {toggleVOR ?
                                        <div className=''>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <NavLink to='/dashboard' className='menu-link-item'>Site Health Metrics</NavLink>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <NavLink to='/edgepc' className='menu-link-item'>Edge PC Configuration</NavLink>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <NavLink to='/vor-configuration' className='menu-link-item'>SV / LV Configuration</NavLink>
                                                </ListItemButton>
                                            </ListItem>
                                            {user.canWrite ?
                                                <ListItem disablePadding>
                                                    <ListItemButton>
                                                        <NavLink to='/ImportSKU' className='menu-link-item'>Import SKU's</NavLink>
                                                    </ListItemButton>
                                                </ListItem> : ""
                                            }
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <NavLink to='/sku-update' className='menu-link-item'>SKU Update</NavLink>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <NavLink to='/CICD-UI-Deployment' className='menu-link-item'>Deployment</NavLink>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <NavLink to='/CICD-UI-Deployment-Tracking' className='menu-link-item'>Deployment Tracking</NavLink>
                                                </ListItemButton>
                                            </ListItem>
                                            {/* <ListItem disablePadding>
                                                <ListItemButton>
                                                    <NavLink to='/QuickSightHealthMetrics' className='menu-link-item'>VOR Health Metrics</NavLink>
                                                </ListItemButton>
                                            </ListItem> */}
                                
                                        </div> : ""
                                    }
                                </List>
                                <div className='logout-cont'>
                                    <Button className='Logout' onClick={signOut} color="inherit"><LogoutIcon className='logout-icon' /> Logout</Button>
                                </div>
                            </Drawer>
                            <Main open={open}>
                                <DrawerHeader />
                                <Routes>
                                    <Route exact path="/" element={<CustomerManagement />} />
                                    <Route exact path="/dashboard" element={< DashBoard />} />
                                    <Route exact path="/edgepc" element={<EdgePCS />} />
                                    <Route exact path="/locations" element={<LocationManagement />} />
                                    <Route exact path="/vor-configuration" element={<VORConfiguration />} />
                                    <Route exact path="/CICD-UI-Deployment" element={<CICDUIDeploy />} />
                                    <Route exact path="/CICD-UI-Deployment-Tracking" element={<CICDUIDeploymentTracking />} />
                                    <Route exact path="/sku-update" element={<SkuUpdate />} />
                                    <Route exact path="/ImportSKU" element={<ImportSKU />} />
                                    <Route exact path="/users" element={<UserManagement />} />
                                    {/* <Route exact path="/QuickSightHealthMetrics" element={<QuickSightHealthMetrics />} /> */}
                                </Routes>
                            </Main>

                        </Box>

                    </BrowserRouter>

                    <Snackbar open={props.alertNotification.show} autoHideDuration={5000} onClose={handleClose}>
                        <Alert severity={props.alertNotification.type} sx={{ width: '100%' }}>
                            {props.alertNotification.message}
                        </Alert>
                    </Snackbar>
                </>)
                : (
                    showAccess ? <AccessDenied /> : ''
                )
            }
        </>

    );
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideNotification: () => {
            dispatch(AppActions.SHOW_NOTIFICATION({ type: "", message: "", show: false }))
        },
        setUserInfo: (userInfo) => {
            dispatch(AppActions.SET_USER_INFO(userInfo));
        }
    }
}
const mapStateToProps = (state) => {
    return {
        alertNotification: state.application.alertNotification,
        user: state.application.user
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedApp);

