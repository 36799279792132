import axios from "axios";
import { json } from "react-router";
const baseURL = process.env.REACT_APP_API_URL;
let devbaseURL = `${baseURL}/Central`;
let adminSupportURL = `${baseURL}/VorAdminSupportPortal`

const loggedInUserIDToken = () => {
    return localStorage.getItem("authToken");
}

export const getApplications = (authToken) => {
    return axios.get(`${devbaseURL}/application?limit=1000&offset=0`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const getVORApplications = (authToken) => {
    return axios.get(`${adminSupportURL}/application?limit=1000&offset=0`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const getDeploymentTypes = (authToken) => {
    return axios.get(`${adminSupportURL}/deployment_type`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const getCustomers = (authToken) => {
    return axios.get(`${devbaseURL}/customer?limit=1000&offset=0`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const addCustomer = (customerName, shortName, status, applicationsList) => {
    const params = JSON.stringify({
        "customer_name": customerName,
        "short_name": shortName,
        "active": status,
        "applications": applicationsList
    });

    return axios.post(`${devbaseURL}/customer`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const editCustomer = (customerId, customerName, shortName, status, applicationsList) => {
    const params = JSON.stringify({
        "customer_name": customerName,
        "active": status,
        "applications": applicationsList
    });

    return axios.put(`${devbaseURL}/customer/${customerId}`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const fetchCustomersWithDC = () => {
    return axios.get(`${adminSupportURL}/customer_dcs`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const fetchEdgePC = (distributionCenterId) => {
    return axios.get(`${adminSupportURL}/edge_pc?dc_id=${distributionCenterId}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const fetchEdgePcConfiguration = (edgePcId) => {
    return axios.get(`${adminSupportURL}/edge_pc_configuration?edge_pc_id=${edgePcId}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const addEdgePC = (edgePC) => {
    const params = JSON.stringify(edgePC);
    return axios.post(`${adminSupportURL}/edge_pc`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const editEdgePC = (edgePC, edgePCId) => {
    const params = JSON.stringify(edgePC);
    return axios.put(`${adminSupportURL}/edge_pc/${edgePCId}`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const fetchTandems = (distributionCenterId) => {
    return axios.get(`${adminSupportURL}/tandem_details?dc_id=${distributionCenterId}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const addTandem = (tandemInfo) => {
    const params = JSON.stringify(tandemInfo);
    return axios.post(`${adminSupportURL}/tandem_details`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const editTandem = (tandemInfo, tandemId) => {
    const params = JSON.stringify(tandemInfo);
    return axios.put(`${adminSupportURL}/tandem_details/${tandemId}`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const deleteTandem = (tandemId) => {
    return axios.delete(`${adminSupportURL}/tandem_details/${tandemId}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const getEdgeSerials = (deviceType) => {
    let serialsUrl = (deviceType) ? `available_edge_serials?device_type=` + deviceType : `available_edge_serials`;
    return axios.get(`${adminSupportURL}/${serialsUrl}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const getLocations = (customerId, selectedCustomerId) => {
    let url = "location?limit=1000&offset=0";
    if (customerId) url += `${url}&central_customer_id=${selectedCustomerId}`
    return axios.get(`${devbaseURL}/${url}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const addLocation = (customerId, locationName, address, latitude, longitude, status, email, timezone) => {
    const params = JSON.stringify({
        "location_name": locationName,
        "address": address,
        "latitude": latitude,
        "longitude": longitude,
        "active": status,
        "time_zone": timezone,
        "email": email,
        "central_location_type_id": 1,
        "central_customer_id": customerId
    });

    return axios.post(`${devbaseURL}/location`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}

export const editLocation = (locationId, customerId, locationName, address, latitude, longitude, status, email, timezone, callUpdatedLocationList) => {
    const params = JSON.stringify({
        "location_name": locationName,
        "address": address,
        "latitude": latitude,
        "longitude": longitude,
        "active": status,
        "time_zone": timezone,
        "email": email,
        "central_location_type_id": 1,
        "central_customer_id": customerId
    });

    return axios.put(`${devbaseURL}/location/${locationId}`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}

export const editVorConfiguration = (configurationId, customerId, dcId, edgePcId, propertyName, propertyValue, status) => {
    const params = JSON.stringify({
        "customer_id": customerId,
        "dc_id": dcId,
        "edge_pc_id": edgePcId,
        "property_name": propertyName,
        "property_value": propertyValue,
        "status": status,
    });

    return axios.put(`${adminSupportURL}/edge_pc_configuration/${configurationId}`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}

export const addVorConfiguration = (customerId, dcId, edgePcId, propertyName, propertyValue, status) => {
    let params = { configs: [] };
    let paramsTemp = {
        "customer_id": customerId,
        "dc_id": dcId,
        "edge_pc_id": edgePcId,
        "property_name": propertyName,
        "property_value": propertyValue,
        "status": status,
    };
    params.configs.push(paramsTemp);

    return axios.post(`${adminSupportURL}/edge_pc_configuration`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}

export const addVorConfigurationsList = (customerId, dcId, edgePcId, properties) => {
    var params = null;
    if (properties) {
        var paramsTemp = properties.map((item) => {
            return {
                "customer_id": customerId,
                "dc_id": dcId,
                "edge_pc_id": edgePcId,
                "property_name": item.property_name,
                "property_value": item.property_value,
                "status": item.status
            }

        });
        params = paramsTemp;
    }

    let newParams = { configs: params };

    return axios.post(`${adminSupportURL}/edge_pc_configuration`, newParams,

        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const fetchPropertyNames = (applicationId, edgePcId) => {
    return axios.get(`${adminSupportURL}/property_name?application_type_id=${applicationId}&edge_pc_id=${edgePcId}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const fetchPropertyNamesEdit = (applicationId) => {
    return axios.get(`${adminSupportURL}/property_name?application_type_id=${applicationId}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const fetchBrands = (authToken, customerId) => {
    let brandsUrl = (customerId) ? `brand?customer_id=` + customerId : `brand`;
    return axios.get(`${adminSupportURL}/${brandsUrl}`,
        {
            headers: {
                "Authorization": authToken,
            },
        })
}
export const fetchPackageType = (authToken, customerId) => {
    let packageTypeUrl = (customerId) ? `package_type?customer_id=` + customerId : `package_type`;

    return axios.get(`${adminSupportURL}/${packageTypeUrl}`,
        {
            headers: {
                "Authorization": authToken,
            },
        })
}

export const getSkusByDesc = (authToken, customerId) => {

    let skubyDescUrl = (customerId) ? `vor_reference?customer_id=` + customerId : `vor_reference`;
    return axios.get(`${adminSupportURL}/${skubyDescUrl}`,
        {
            headers: {
                "Authorization": authToken,
            },
        })

}
export const getSkuByVORRefrenceNumber = (referenceNumber, upc) => {
    let skubyrefrenceUrl = (referenceNumber) ? `sku_details/?vor_reference_number=` + referenceNumber : `sku_details/?upc=` + upc;
    return axios.get(`${adminSupportURL}/${skubyrefrenceUrl}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })

}

export const updateWeight = (action, upc, reference, weight, height, length, width, packageTypeId, unitsPerCase, brandId, skuPackagesPerCase, isWeightVerified, dateweightverifiedCopy, selectedDate) => {
    const params = JSON.stringify({
        "action": action,
        "UPC": upc,
        "ref_no": reference,
        "weight": weight,
        "height": height,
        "length": length,
        "width": width,
        "package_type_id": packageTypeId,
        "units_per_case": unitsPerCase,
        "brand_id": brandId,
        "sku_packages_per_case": skuPackagesPerCase,
        "is_weight_verified_on_scale": isWeightVerified,
        "date_weight_was_verified": selectedDate ? selectedDate : dateweightverifiedCopy
    });

    return axios.post(`${adminSupportURL}/update_merge_weight`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}

export const mergeWeight = (action, customerId, delPackageTypeId, mergePackageTypeId) => {
    const params = JSON.stringify({
        "action": action,
        "customer_id": customerId,
        "del_package_type_id": delPackageTypeId,
        "merge_package_type_id": mergePackageTypeId
    });

    return axios.post(`${adminSupportURL}/update_merge_weight`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const getVorCustomers = () => {
    return axios.get(`${adminSupportURL}/customer`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const addSKU = (file_Name, content, selectedCustomerName) => {
    const params = JSON.stringify({
        "file_name": file_Name,
        "content": content,
        "customer_name": selectedCustomerName
    });


    return axios.post(`${adminSupportURL}/SkuImport`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        }).then(response => {
            return response.data
        })
        .catch(error => {
            return error
        });
}
export const getRoles = () => {
    return axios.get(`${devbaseURL}/role?limit=1000&offset=0`,
        {
            headers: {
                "Authorization": localStorage.getItem("authToken"),
            },
        })
}
export const getUsers = (customerId, locationId) => {
    let url = "user?limit=1000&offset=0";
    if (customerId && customerId != -1) url += `${url}&central_customer_id=${customerId}`
    if (customerId == -1) url += `${url}&is_rehrig_user=1`
    if (locationId) url += `${url}&central_location_id=${locationId}`
    return axios.get(`${devbaseURL}/${url}`,
        {
            headers: {
                "Authorization": localStorage.getItem("authToken"),
            },
        })
}
export const addUser = (user) => {
    const params = JSON.stringify(user);
    let url = "user";
    return axios.post(`${devbaseURL}/${url}`, params,
        {
            headers: {
                "Authorization": localStorage.getItem("authToken"),
            },
        })
}
export const editUser = (user, userId) => {
    const params = JSON.stringify(user);
    let url = "user";
    return axios.put(`${devbaseURL}/${userId}`, params,
        {
            headers: {
                "Authorization": localStorage.getItem("authToken"),
            },
        })
}
export const fetchDockDoor = (dcId) => {
    return axios.get(`${adminSupportURL}/dock_door?dc_id=${dcId}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}

export const addDockDoor = (dockDoorName, slsPortalIp, topic, rpDeviceIp, selectedEdgeSerials, status, customerId, dcId) => {
    let tempSelectedEdgeSerials = (selectedEdgeSerials === 'No Selection' ? null : selectedEdgeSerials);
    const params = JSON.stringify({
        "edge_dock_door_name": dockDoorName,
        "customer_id": customerId,
        "dc_id": dcId,
        "topic": topic,
        "dock_door_sls_ip_address": slsPortalIp,
        "raspberry_pi_device_ip_address": rpDeviceIp,
        "edge_device_generated_serial_number": tempSelectedEdgeSerials,
        "active": status,
    });

    return axios.post(`${adminSupportURL}/dock_door`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const editDockDoor = (dockDoorId, dockDoorName, slsPortalIp, topic, rpDeviceIp, selectedEdgeSerials, status, customerId, dcId) => {
    let tempSelectedEdgeSerials = (selectedEdgeSerials === 'No Selection' ? null : selectedEdgeSerials);

    const params = JSON.stringify({
        "edge_dock_door_name": dockDoorName,
        "customer_id": customerId,
        "dc_id": dcId,
        "topic": topic,
        "dock_door_sls_ip_address": slsPortalIp,
        "raspberry_pi_device_ip_address": rpDeviceIp,
        "edge_device_generated_serial_number": tempSelectedEdgeSerials,
        "active": status,
    });

    return axios.put(`${adminSupportURL}/dock_door?dock_door_id=${dockDoorId}`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const fetchnetworkConfiguration = (dcId) => {
    return axios.get(`${adminSupportURL}/network_config?dc_id=${dcId}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const addNetworKConfiguration = (customerId, dcId, dnsAddress, gatewayAddress, netmaskAddress) => {

    const params = JSON.stringify({
        "customer_id": customerId,
        "dc_id": dcId,
        "dns_address": dnsAddress,
        "gateway_address": gatewayAddress,
        "netmask_address": netmaskAddress
    });

    return axios.post(`${adminSupportURL}/network_config`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const editNetworKConfiguration = (customerId, dcId, dnsAddress, networkConfigID, gatewayAddress, netmaskAddress) => {
    const params = JSON.stringify({
        "dc_id": dcId,
        "customer_id": customerId,
        "dns_address": dnsAddress,
        "gateway_address": gatewayAddress,
        "netmask_address": netmaskAddress
    });

    return axios.put(`${adminSupportURL}/network_config/${networkConfigID}`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const fetchpalletConfiguration = (customerId, dcId) => {
    return axios.get(`${adminSupportURL}/pallet?customer_id=${customerId}&dc_id=${dcId}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const addPalletConfiguration = (customerId, dcId, palletType, palletSchema, palletWeight, isfullPallet) => {
    const params = JSON.stringify({
        "customer_id": customerId,
        "dc_id": dcId,
        "pallet_type": palletType,
        "pallet_schema": palletSchema,
        "pallet_weight_in_lbs": palletWeight,
        "is_full_pallet": isfullPallet,
    });

    return axios.post(`${adminSupportURL}/pallet`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const editPalletConfiguration = (customerId, dcId, palletType, palletSchema, palletWeight, isfullPallet, palletSchemaID) => {
    const params = JSON.stringify({
        "customer_id": customerId,
        "dc_id": dcId,
        "pallet_type": palletType,
        "pallet_schema": palletSchema,
        "pallet_weight_in_lbs": palletWeight,
        "is_full_pallet": isfullPallet,
    });
    return axios.put(`${adminSupportURL}/pallet/?pallet_schema_id=${palletSchemaID}`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}

export const fetchCodeDeploy = (dcId) => {
    return axios.get(`${adminSupportURL}/software_deployment_window?dc_id=${dcId}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const createCodeDeploy = (dayOfTheWeek, startTime, endTime, customerId, dcId) => {

    let params = {
        "intervals": [
            {
                "dc_id": dcId,
                "customer_id": customerId,
                "day_of_the_week": dayOfTheWeek ? dayOfTheWeek : null,
                "start_time": startTime ? startTime : null,
                "end_time": endTime ? endTime : null
            }
        ]
    }

    return axios.post(`${adminSupportURL}/software_deployment_window`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const editCodeDeploy = (dayOfTheWeek, startTime, endTime, customerId, dcId, codeDeployWindowId) => {

    let params = {
        "dc_id": dcId,
        "customer_id": customerId,
        "day_of_the_week": dayOfTheWeek,
        "start_time": startTime,
        "end_time": endTime
    }

    return axios.put(`${adminSupportURL}/software_deployment_window/${codeDeployWindowId}`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const deleteCodeDeploy = (codeDeployWindowId) => {
    return axios.delete(`${adminSupportURL}/software_deployment_window/${codeDeployWindowId}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}
export const createEdgeDeploy = (selectedCustomerId, selectedLocationId, selectedEdgePcId, deploymentType, modelSetid, scheduleDate, applicationId, isFullCustomerDeploy, isFullDcDeploy, isEdgePcDeploy) => {
    let params = null;
    if (isFullCustomerDeploy === true && applicationId === "" && modelSetid === undefined) {
        params = {
            "is_full_customer_deployment": isFullCustomerDeploy,
            "is_full_dc_deployment": isFullDcDeploy,
            "is_edge_pc_deployment": isEdgePcDeploy,
            "customer_id": selectedCustomerId,
            "deployment_type_id": deploymentType,
            "schedule_time": scheduleDate
        }

    }
    if (isFullCustomerDeploy === true && applicationId !== "" && modelSetid === undefined) {
        params = {
            "is_full_customer_deployment": isFullCustomerDeploy,
            "is_full_dc_deployment": isFullDcDeploy,
            "is_edge_pc_deployment": isEdgePcDeploy,
            "customer_id": selectedCustomerId,
            "application_type_id": applicationId,
            "deployment_type_id": deploymentType,
            "schedule_time": scheduleDate
        }

    }
    if (isFullCustomerDeploy === true && applicationId !== "" && modelSetid !== undefined) {
        params = {
            "is_full_customer_deployment": isFullCustomerDeploy,
            "is_full_dc_deployment": isFullDcDeploy,
            "is_edge_pc_deployment": isEdgePcDeploy,
            "customer_id": selectedCustomerId,
            "application_type_id": applicationId,
            "deployment_type_id": deploymentType,
            "model_set_id": Number(modelSetid),
            "schedule_time": scheduleDate,
        }
    }
    if (isFullDcDeploy === true && applicationId === "" && modelSetid === undefined) {
        params = {
            "is_full_customer_deployment": isFullCustomerDeploy,
            "is_full_dc_deployment": isFullDcDeploy,
            "is_edge_pc_deployment": isEdgePcDeploy,
            "customer_id": selectedCustomerId,
            "dc_id": selectedLocationId,
            "deployment_type_id": deploymentType,
            "schedule_time": scheduleDate
        }
    }
    if (isFullDcDeploy === true && applicationId !== "" && modelSetid === undefined) {
        params = {
            "is_full_customer_deployment": isFullCustomerDeploy,
            "is_full_dc_deployment": isFullDcDeploy,
            "is_edge_pc_deployment": isEdgePcDeploy,
            "customer_id": selectedCustomerId,
            "dc_id": selectedLocationId,
            "application_type_id": applicationId,
            "deployment_type_id": deploymentType,
            "schedule_time": scheduleDate
        }
    }
    if (isFullDcDeploy === true && applicationId !== "" && modelSetid !== undefined) {
        params = {
            "is_full_customer_deployment": isFullCustomerDeploy,
            "is_full_dc_deployment": isFullDcDeploy,
            "is_edge_pc_deployment": isEdgePcDeploy,
            "customer_id": selectedCustomerId,
            "dc_id": selectedLocationId,
            "application_type_id": applicationId,
            "deployment_type_id": deploymentType,
            "model_set_id": Number(modelSetid),
            "schedule_time": scheduleDate
        }
    }
    if (isEdgePcDeploy === true && applicationId === "" && modelSetid === undefined) {
        params = {
            "is_full_customer_deployment": isFullCustomerDeploy,
            "is_full_dc_deployment": isFullDcDeploy,
            "is_edge_pc_deployment": isEdgePcDeploy,
            "customer_id": selectedCustomerId,
            "dc_id": selectedLocationId,
            "edge_pc_id": selectedEdgePcId,
            "deployment_type_id": deploymentType,
            "schedule_time": scheduleDate
        }
    }
    if (isEdgePcDeploy === true && applicationId !== "" && modelSetid === undefined) {
        params = {
            "is_full_customer_deployment": isFullCustomerDeploy,
            "is_full_dc_deployment": isFullDcDeploy,
            "is_edge_pc_deployment": isEdgePcDeploy,
            "customer_id": selectedCustomerId,
            "dc_id": selectedLocationId,
            "edge_pc_id": selectedEdgePcId,
            "application_type_id": applicationId,
            "deployment_type_id": deploymentType,
            "schedule_time": scheduleDate
        }
    }
    if (isEdgePcDeploy === true && modelSetid !== "null" && modelSetid !== undefined) {
        params = {
            "is_full_customer_deployment": isFullCustomerDeploy,
            "is_full_dc_deployment": isFullDcDeploy,
            "is_edge_pc_deployment": isEdgePcDeploy,
            "customer_id": selectedCustomerId,
            "dc_id": selectedLocationId,
            "edge_pc_id": selectedEdgePcId,
            "application_type_id": applicationId,
            "deployment_type_id": deploymentType,
            "model_set_id": Number(modelSetid),
            "schedule_time": scheduleDate,

        }
    }

    return axios.post(`${adminSupportURL}/edge_deployment`, params,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}

export const fetchSiteHealthDetails = (selectedCustomerId, selectedLocationId, selectedTranDate, halfPallets, fullPallets) => {
    console.log(selectedLocationId)
   let paramVal = null;
   let halfPalletValue = 0
   let fullPalletValue = 0
    if (halfPallets == true) {
        halfPalletValue = 1
    }
    if (fullPallets == true) {
        fullPalletValue = 1
    }
    if(selectedCustomerId === null || selectedCustomerId === 'All' && selectedLocationId ){
        paramVal = `daily_summary_report?transaction_date=${selectedTranDate}&is_full_pallet=${fullPalletValue}&is_half_pallet=${halfPalletValue}`
    }
    else if(selectedCustomerId && selectedCustomerId !== 'All' && !selectedLocationId){
        paramVal = `daily_summary_report?transaction_date=${selectedTranDate}&customer_id=${selectedCustomerId}&is_full_pallet=${fullPalletValue}&is_half_pallet=${halfPalletValue}`
    }
    else if((selectedCustomerId !== null && selectedCustomerId !== 'All') && (selectedLocationId === null || selectedLocationId === 'All')){
        paramVal = `daily_summary_report?transaction_date=${selectedTranDate}&customer_id=${selectedCustomerId}&is_full_pallet=${fullPalletValue}&is_half_pallet=${halfPalletValue}`
    }
    else if((selectedCustomerId !== null && selectedCustomerId !== 'All') && (selectedLocationId !== null && selectedLocationId !== 'All')){
        paramVal = `daily_summary_report?transaction_date=${selectedTranDate}&customer_id=${selectedCustomerId}&dc_id=${selectedLocationId}&is_full_pallet=${fullPalletValue}&is_half_pallet=${halfPalletValue}`
    }
    
    else{}

    return axios.get(`${adminSupportURL}/${paramVal}`,
        {
            headers: {
                "Authorization": loggedInUserIDToken(),
            },
        })
}

export const getEmbeddedURLForHealthSiteMetrics = () => {
    return axios.get(`${baseURL}/qs/v1/embed-url?app_name=VOR&id=HEALTH_METRICS&type=dashboard`,
    {
        headers: {
            "Authorization": loggedInUserIDToken(),
        },
    })
}

export const getDeploymentDetails = () => {
    return axios.get(`${adminSupportURL}/deployment_schedule_details`,
    {
        headers: {
            "Authorization": loggedInUserIDToken(),
        },
    })
}

export const getModelSkuData = (deploymentSheduleID, modelSetID,  deploymentDate) => {
    return axios.get(`${adminSupportURL}/model_sku_data?deployment_schedule_id=${deploymentSheduleID}&model_set_id=${modelSetID}&deployment_date=${deploymentDate}`,
    {
        headers: {
            "Authorization": loggedInUserIDToken(),
        },
    })
}