import React, { useState, useEffect, useLayoutEffect } from 'react';
import Header from '../Header/Header';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';
import SkuUpdateTabAttribute from '../SkuUpdateTabAttribute/SkuUpdateTabAttribute';
import SkuUpdateTabMergePackageType from '../SkuUpdateTabMergePackageType/SkuUpdateTabMergePackageType';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Auth } from 'aws-amplify';


const SkuUpdate = (props) => {
    const [brands, setBrands] = useState(props.brands);
    const [packageType, setPackageType] = useState(props.packageType);
    const [selectedTab, setSelectedTab] = useState(0);

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#fff"
    });

    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            localStorage.setItem('authToken', jwt);
            props.fetchVorCustomers();
        })
    }, []);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
    return (
        <div style={{ width: '100%' }}>
            <div>
                <h3 style={{ textAlign: 'left' }}>SKU Update</h3>
                {props.error && <Alert severity="error" sx={{ marginBottom: '20px' }}>{props.error}</Alert>}
                <Box sx={{ width: '800px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="SKU attributes update" />
                            <Tab label="Merge Package Type" />
                        </Tabs>
                    </Box>
                    <TabPanel value={selectedTab} index={0}>
                        <SkuUpdateTabAttribute customersVorList={props.customersVorList}/>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <SkuUpdateTabMergePackageType customersVorList={props.customersVorList}/>
                    </TabPanel>
                </Box>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        error: state.skuUpdate.error,
        customersVorList: state.skuUpdate.customersVorList
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchVorCustomers: () => dispatch(Actions.LOAD_VOR_CUSTOMERS())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkuUpdate);